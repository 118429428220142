<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Purchase Order</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>
                
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>
                
                <purchase-order-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick" :bookingEditClick="bookingEditClick" :pdfClick="pdfClick" :proformaInvoiceClick="proformaInvoiceClick"/>
                <purchase-order-status-form ref="PurchaseOrderStatusForm" :reload="reload"/>
                <purchase-order-pdf ref="pdf" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import purchaseOrderGrid from '../PurchaseOrder/Grid/PurchaseOrderGrid.vue';
import poServices from './Script/PurchaseOrderScript.js';
import PurchaseOrderStatusForm from '../PurchaseOrder/Component/PurchaseOrderStatusForm.vue';
import PurchaseOrderPDF from '../../../assets/template/PurchaseOrderPDFTemplate.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'PurchaseOrder',
    components: {
        'purchase-order-grid': purchaseOrderGrid,
        'purchase-order-status-form': PurchaseOrderStatusForm,
        'purchase-order-pdf': PurchaseOrderPDF
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Purchase Order');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$router.push({ name: 'Purchase Order Form', params: {  formtype:'Add', id:' ', view:'new', reedit:'true' } });
        },
        editClick(id, view, reedit){
            // this.$router.push({ name: 'Purchase Order Form', params: { formtype:'Edit', id:id, view:view } });
            const routeData = this.$router.resolve({ name: 'Purchase Order Form', params: { formtype:'Edit', id:id, view:view, reedit:reedit } });
            window.open(routeData.href, '_blank');
        },
        bookingEditClick(po_id, view){
            const routeData = this.$router.resolve({ name: 'Booking Purchase Order Form', params: { formtype:'Edit', boId:0, poId:po_id, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await poServices.getPurchaseOrderQuery(id);
            
            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    this.$loading(true);
                    //Grid Item and Booking
                    var gridItem = poServices.editItemGridDataGenerator(data.purchase_order_detail);
                    var gridBookingItem = poServices.editItemGridDataGenerator(data.booking_order);

                    var poDetail = poServices.transactionPurchaseOrderDetail(gridItem, gridBookingItem, data.tax_percentage);
                    
                    const poData = {
                        purchase_order_number: data.purchase_order_number,
                        purchase_order_date: data.purchase_order_date,
                        sp_number: null,
                        status: "Delete",
                        project_id: data.project_id,
                        customer_id: data.customer_id,
                        storage_id: data.storage_id,
                        price_category_id: data.price_category_id,
                        purchase_order_sequence: data.purchase_order_sequence,
                        purchase_order_type: data.purchase_order_type,
                        notes: data.notes,
                        printed_notes: data.printed_notes,
                        address: data.address,
                        attachment: null,
                        down_payment: data.down_payment,
                        tax_percentage: data.tax_percentage,
                        // total: data.total,
                        sample: data.sample,
                        term_of_payment: data.term_of_payment,
                        booking_purchase_order_id: data.booking_purchase_order_id,
                        transaction_purchase_order_detail: poDetail
                    }

                    const variables = {
                        id : id,
                        data : poData
                    }

                    poServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });
        },        
        statusClick(id) {
            this.$refs.PurchaseOrderStatusForm.statusClick(id);
        },
        pdfClick(id, status, type) {
            if (type == "Booking"){
                this.$swal("Error", "Persetujuan Pembelian tidak dapat di cetak pada purchase order booking", "error");
            } 
            else {
                if (status == "Process" || status == "Finish" ) {
                    // this.$swal(sweet_alert.print).then((result) => {
                    //     if (result.isConfirmed == true) {
                    //         this.$refs.pdf.generatePDF(id);
                    //     }else if (result.isDenied) {
                    const routeData = this.$router.resolve({name: 'Purchase Order Html Template', params: { id:id }});
                    window.open(routeData.href, '_blank');
                    //     }
                    // });
                }
                else {
                    this.$swal("Error", response.printStatusAlert, "error");
                }
            }
        },
        proformaInvoiceClick(id, itemCount, itemBookingCount, status) {
            if (status == "Process" || status == "Finish" ) {
                this.$swal(sweet_alert.print_proforma_invoice).then((result) => {
                    if (result.isConfirmed == true) {
                        if(itemCount == 0){
                            this.$swal("Error", "Proforma Invoice tidak dapat dicetak karena item tidak ada", "error");
                        }else{
                            const routeData = this.$router.resolve({name: 'Proforma Invoice Report Html Template', params: { id:id }});
                            window.open(routeData.href, '_blank');
                        }
                    }else if (result.isDenied) {
                        if(itemBookingCount == 0){
                            this.$swal("Error", "Proforma Invoice tidak dapat dicetak karena item booking tidak ada", "error");
                        }else{
                            const routeData = this.$router.resolve({name: 'Proforma Invoice Booking Report Html Template', params: { id:id }});
                            window.open(routeData.href, '_blank');
                        }
                    }
                });
            }
            else {
                this.$swal("Error", response.printStatusAlert, "error");
            }
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnRevisi").on('click', function() {
                    vue.$refs.grid.changeStatus('Revisi');
                });
                $("#btnChecking").on('click', function() {
                    vue.$refs.grid.changeStatus('Checking');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
                $("#btnProcess").on('click', function() {
                    vue.$refs.grid.changeStatus('Process');
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>