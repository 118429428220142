<template>
    <div>
        <div class="modal fade" id="PurchaseOrderStatusModal" tabindex="-1" aria-labelledby="PurchaseOrderStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="PurchaseOrderStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{PurchaseOrderNo}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import poServices from '../Script/PurchaseOrderScript.js';
import { response } from '../../../../infrastructure/constant/response';
import { sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PurchaseOrderStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : '',
            PurchaseOrderNo : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(data){
            this.ModalTitle = 'Edit Status Purchase Order: ';
            this.Id = data.purchase_order_id;
            this.PurchaseOrderNo = data.purchase_order_number;
            
            this.StatusData = await globalfunc.globalDropdown('ddl_status_purchase_order');
            this.Status = data.status;
            
            window.$('#PurchaseOrderStatusModal').modal('show');
        },
        async saveClick(){
            this.$loading(true);
            var data = await poServices.getPurchaseOrderQuery(this.Id);
            
            //Grid Item and Booking
            var gridItem = poServices.editItemGridDataGenerator(data.purchase_order_detail);
            var gridBookingItem = poServices.editItemGridDataGenerator(data.booking_order);
            
            var poDetail = poServices.transactionPurchaseOrderDetail(gridItem, gridBookingItem, data.tax_percentage);
            
            //Status Delete PO_Number null
            var status = this.Status;
            var SpNumber = data.sp_number;
            if(status == "Delete") {
                SpNumber = null
            }

            const poData = {
                // purchase_order_number: PurchaseOrderNumber,
                purchase_order_number: data.purchase_order_number,
                purchase_order_date: data.purchase_order_date,
                sp_number: SpNumber,
                status: this.Status,
                project_id: data.project_id,
                customer_id: data.customer_id,
                storage_id: data.storage_id,
                price_category_id: data.price_category_id,
                purchase_order_sequence: data.purchase_order_sequence,
                purchase_order_type: data.purchase_order_type,
                notes: data.notes,
                printed_notes: data.printed_notes,
                address: data.address,
                attachment: null,
                down_payment: data.down_payment,
                tax_percentage: data.tax_percentage,
                // total: data.total,
                sample: data.sample,
                term_of_payment: data.term_of_payment,
                transaction_purchase_order_detail: poDetail
            }
            
            const variables = {
                id : this.Id,
                data : poData
            }
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    poServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#PurchaseOrderStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });
            this.$loading(false);
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>