<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            v-on:databound="dataBound"
            :resizable="true">
        </kendo-grid>

        <file-viewer ref="fileViewer"/>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import purchaseOrderServices from '../Script/PurchaseOrderScript.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import FileViewer from '../../../../shared/FileViewer.vue';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PurchaseOrderGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'file-viewer': FileViewer,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'bookingEditClick', 'pdfClick', 'proformaInvoiceClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"transaction_purchase_order\".\"status\"" },
                                { field: "created_at", new_field: "\"transaction_purchase_order\".\"created_at\"" },
                                { field: "customer_name", new_field: "\"Customer\".\"contact_name\"" },
                                { field: "total", new_field: "\"transaction_purchase_order\".\"total\"" },
                                { field: "payment_method", new_field: "\"Project\".project_detail->>'cash'::text" },
                                { field: "created_by", new_field: "\"Created\".\"myname\"" },
                                { field: "approved_by", new_field: "\"Approved\".\"myname\"" },
                                { field: "notes", new_field: "\"transaction_purchase_order\".\"notes\"" },
                                { field: "sales_name", new_field: "\"Project__Sales\".contact_name" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: purchaseOrderServices.readPurchaseOrderQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionPurchaseOrder.transaction_purchase_order == null){
                            return [];
                        }else{
                            return response.data.GetTransactionPurchaseOrder.transaction_purchase_order;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionPurchaseOrder.transaction_purchase_order == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionPurchaseOrder.total;
                        }
                    },
                    model: {
                        fields: {
                            purchase_order_date: {type: "date"},
                            created_at: {type: "date"},
                            total: {type: "number"},
                            project_type: {type: "dropdown"},
                            term_of_payment: {type: "number"}
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                // { template:this.columnTemplate, headerTemplate: this.headerTemplate, width: 40, headerAttributes:{ style: "text-align:center;" }, attributes:{style: "text-align:center;"} },
                { title: "Action", width: 240, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 90, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "purchase_order_number", title: "No. PO", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0: yyyy-MM-dd}"  },
                { field: "sp_number", title: "No. SP", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "purchase_order_date", title: "Tanggal PO", width: 125, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(purchase_order_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "created_at", title: "Tanggal Dibuat", width: 180, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"},
                { field: "customer_name", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "total", title: "Total", width: 130, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= kendo.toString(Math.round(total), 'n') #" },
                { field: "project_type", title: "Tipe", width: 80, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    filterable: { ui: this.projectTypeFilter },
                    template: '#= project_type == 1 ? "Project" : "Retail" #' },
                { field: "project_name", title: "Nama Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "payment_method", title: "Cara Bayar", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "term_of_payment", title: "Jatuh Tempo (Hari)", width: 180, headerAttributes:{ style: "text-align:center; font-weight:bold;"}, attributes:{style: "text-align:right;"} },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "attachment", title: "Attachment", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template:this.columnFile },
                { field: "created_by", title: "Dibuat Oleh", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "approved_by", title: "Disetujui Oleh", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "sales_name", title: "Sales", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },     
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            hf_CheckAll_Filter:"false",
            hf_ID_Filter:"",
            projectTypeFilterData: [
                {value: 1, label: 'Project'},
                {value: 2, label: 'Retail'}
            ],
        };
    },
    mounted: async function() {
        var vueComponent = this;
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
        var bookingEditClick = this.$props.bookingEditClick;
        var pdfClick = this.$props.pdfClick;
        var proformaInvoiceClick = this.$props.proformaInvoiceClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.purchase_order_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            var reedit = false;
            
            if((dataItem.status == 'New' || dataItem.status == 'Revisi') && (dataItem.project.forecast_type == 'None' || dataItem.project.forecast_type == 'Total')){
                reedit = true
            }
            
            if (dataItem.purchase_order_type != 'Booking') {
                editClick(dataItem.purchase_order_id, false, reedit);
            }
            else {
                bookingEditClick(dataItem.purchase_order_id, false);
            }
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            if (dataItem.purchase_order_type != 'Booking') {
                editClick(dataItem.purchase_order_id, true, false);
            }
            else {
                bookingEditClick(dataItem.purchase_order_id, true);
            }
        })
        
        //Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            statusClick(dataItem);
        })

        GridElement.on("click", "#PdfButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            pdfClick(dataItem.purchase_order_id, dataItem.status, dataItem.purchase_order_type)
        })

        GridElement.on("click", "#ProformaInvoiceButton", function (e){
            e.preventDefault();
           
            var dataItem = grid.dataItem(e.target.closest("tr"));
            var itemCount = dataItem.purchase_order_detail == null ? 0 : dataItem.purchase_order_detail.length;
            var itemBookingCount = dataItem.booking_order == null ? 0 : dataItem.booking_order.length;
            
            proformaInvoiceClick(dataItem.purchase_order_id, itemCount, itemBookingCount, dataItem.status);
        })

        //File Form
        GridElement.on("click", "#FileButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            vueComponent.$refs.fileViewer.viewClick(dataItem.attachment);
        })

        GridElement.on("change", "#masterCheckBoxFilter", function (e) {
            e.preventDefault();
            var state= this.checked;
            var data = grid._data;
            
            for (var i = 0; i < data.length; i++) {
                var datarow = data[i]
                var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                
                if (elementRow != null) {
                    if (state) {
                    elementRow.checked = true;
                    grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                    }
                    else
                    {
                    elementRow.checked = false;
                    grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                    }
                }
             }
             var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
              if (state && hf_CheckAll_Filter == "true") {
                    vueComponent.hf_CheckAll_Filter="true";
                }
                else if (!state && hf_CheckAll_Filter == "true") {
                    vueComponent.hf_CheckAll_Filter="false";
                    vueComponent.hf_ID_Filter="";
                }
                else if (state && hf_CheckAll_Filter == "false") {
                    vueComponent.hf_CheckAll_Filter= "true";
                    vueComponent.hf_ID_Filter="";
                }
                else if (!state)
                    vueComponent.hf_CheckAll_Filter="false";
                else
                    vueComponent.hf_CheckAll_Filter= "true";
        });

        GridElement.on("change", ".cbFilter", function (e) {
            e.preventDefault();
            var checked = this.checked;
            var rowElement = grid.tbody.find(e.target.closest("tr"));
            var row = grid.dataItem(e.target.closest("tr"));
            var hfIDValue = vueComponent.hf_ID_Filter;
            var uid = row.uid;
            var dataItem = row.purchase_order_id.toString();
                       
            var checkedIdArray = hfIDValue.split(',');

            var lastvalueHfid =checkedIdArray.slice(-1)[0];
            var hf_CheckAll_Filter = vueComponent.hf_CheckAll_Filter;
           
            if (checked)
                grid.tbody.find("tr[data-uid='" +uid+ "']").addClass("k-state-selected");
            else
                grid.tbody.find("tr[data-uid='" +uid+ "']").removeClass("k-state-selected");
            
            var lastIndex = (hfIDValue.length - dataItem.length)
            if (!checked && hf_CheckAll_Filter == "true") {
            if (hfIDValue == "")
               hfIDValue = dataItem;
                else {
                    //Check if not exist
                    if (hfIDValue.indexOf(dataItem) == "-1")
                        hfIDValue = hfIDValue + "," + dataItem;
                }
             }
            else if (!checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {    
                if(lastvalueHfid != dataItem.toString())
                {
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                }
                else if (lastvalueHfid == dataItem.toString()) 
                {
                    hfIDValue = hfIDValue.replace("," + dataItem, '');  
                }
                else
                {
                    hfIDValue = hfIDValue.replace(dataItem, '');
                }
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');   
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "true") {
                //Check kalau bukan di index terakhir
                if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) != lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace(dataItem + ",", '');
                //Check di index terakhir
                else if (hfIDValue.indexOf(dataItem) != "-1" && hfIDValue.indexOf(dataItem) == lastIndex && hfIDValue.indexOf(",") != "-1")
                    hfIDValue = hfIDValue.replace("," + dataItem, '');
                else
                    hfIDValue = hfIDValue.replace(dataItem, '');
            }
            else if (checked && hf_CheckAll_Filter == "false" || hf_CheckAll_Filter == "") {
                if (hfIDValue == "")
                    hfIDValue = dataItem;
                else {
                    if(!checkedIdArray.includes(dataItem))
                    {
                        hfIDValue = hfIDValue + "," + dataItem;
                    }
                }
            }
            if (checked) {
                rowElement.addClass("k-state-selected");
            } else {
                rowElement.removeClass("k-state-selected");
            }
            vueComponent.hf_ID_Filter = hfIDValue
        });
    },
    methods: {
        projectTypeFilter: function(e) {
            e.kendoDropDownList({
                autoBind: false,
                dataSource: this.projectTypeFilterData,
                dataTextField: "label",
                dataValueField: "value",
                optionLabel: "Pilih Tipe Project"
            });
        },
        headerTemplate() {
            return `<input type="checkbox" id="masterCheckBoxFilter"" />`
        },
        columnTemplate() {
            return `<input type="checkbox" class="cbFilter" />`
        },
        columnButton(){
            var customButton = `&nbsp;&nbsp;
                                <button type="button" class="btn btn-secondary btn-sm rounded" id="PdfButton">
                                    <i class="fa fa-file-pdf-o"></i> </span>
                                </button>
                                &nbsp;&nbsp;
                                <button type="button" class="btn btn-warning btn-sm rounded" id="ProformaInvoiceButton">
                                    <i class="fa fa-file-pdf-o"></i> </span>
                                </button>`;
            return this.$globalfunc.gridActionWithStatusChangesButton("Purchase Order", customButton)
        },
        columnFile(e){
            return this.$globalfunc.gridFileViewButton(e.attachment);
        },
        detailInit: async function (e) {
            var data = await purchaseOrderServices.getPurchaseOrderQuery(e.data.purchase_order_id);
            var html = await purchaseOrderServices.purchaseOrderDetailTemplate(data);

            var itemDetail = purchaseOrderServices.editItemGridDataGenerator(data.purchase_order_detail);
            // var itemBookingDetail = purchaseOrderServices.editItemGridDataGenerator(data.booking_order);
            
            var totalPPNTax = 0;
            var totalGrossDisc = 0;
            var grandTotal = 0;

            if(itemDetail.length > 0){
                itemDetail.forEach(e => {
                    totalGrossDisc = totalGrossDisc + (e.net * e.qty);
                    totalPPNTax = totalPPNTax + (e.net * (data.tax_percentage / 100)) * e.qty;
                });
            }
        
            // if(itemBookingDetail.length > 0){
            //     itemBookingDetail.forEach(e => {
            //         totalGrossDisc = totalGrossDisc + (e.net * e.qty);
            //         totalPPNTax = totalPPNTax + Math.round(e.net * (data.tax_percentage / 100)) * e.qty;
            //     });
            // }

            grandTotal = totalGrossDisc + Math.round(totalPPNTax);

            var itemMergeArray = purchaseOrderServices.mergeItemArray(data);
            
            $('<div/>').appendTo(e.detailCell).html(html);
            $('<div/>').appendTo(e.detailCell).html('<label class="form-label"><b>Daftar Barang</b></label>');
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "detailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(itemMergeArray);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify(
                            {
                                query: options.query,
                                variables: options.variables
                            }
                        );
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return [];
                            else
                                return response;
                        },
                        total: function(response) {
                            if(response == null)
                                return 0;
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize,
                    group: { field: "type", aggregates: [ {field: "total_price", aggregate: "sum"} ] },
                    aggregate: [{ field: "total_price", aggregate: "sum" }]
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                editable: false,
                resizable: true,
                columns: [
                    { field: "type", title: "Tipe", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                        groupHeaderTemplate: "#= value #", hidden: true},
                    { field: 'quantity', title: "Jumlah", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    { field: 'uom', title: "Satuan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'product_code', title: "Kode Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'item_name', title: "Nama Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'vat_per_unit', title: "Harga", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N0}", attributes: { "class": "k-text-right" }, 
                        footerTemplate: `   <div style='float: right'>Total</div><br>
                                            <div style='float: right'>PPN `+ data.tax_percentage +`%</div><br>
                                            <div style='float: right'>Grand Total</div>` },
                    { field: 'total_price', title: "Total",  width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N0}", attributes: { "class": "k-text-right" }, 
                        aggregates: ["sum"],  footerTemplate: ` <div style='float: right'>Rp. #=  kendo.toString(Math.round(sum), 'n0') #</div><br>
                                                                <div style='float: right'>Rp. #=  kendo.toString(`+ totalPPNTax +`, 'n0') #</div><br>
                                                                <div style='float: right'>Rp. #=  kendo.toString(`+ grandTotal +`, 'n0') #</div>` },
                ],
            });
        },
        dataBound: function (e) {
            var grid = this.$refs.grid.kendoWidget();
            var checked = $("#masterCheckBoxFilter").is(':checked');
            var data = grid._data;
            var splitIdArray =  this.hf_ID_Filter.split(',');

            if (checked) {
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];
                    
                    if (elementRow != null) {
                        if (checked && splitIdArray.indexOf(datarow.po_id.toString()) == -1) {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                        else
                        {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                    }
                }
            }else{
                for (var i = 0; i < data.length; i++){
                    var datarow = data[i]
                    var elementRow =  grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").find(".cbFilter")[0];

                    if (elementRow != null) {
                        if (splitIdArray.indexOf(datarow.po_id.toString()) == -1) {
                            elementRow.checked = false;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").removeClass("k-state-selected");
                        }
                        else 
                        {
                            elementRow.checked = true;
                            grid.tbody.find("tr[data-uid='" +datarow.uid+ "']").addClass("k-state-selected");
                        }
                    }
                }
            }
        },
        changeStatus(status){
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"transaction_purchase_order\".\"status\"" },
                                { field: "created_at", new_field: "\"transaction_purchase_order\".\"created_at\"" },
                                { field: "customer_name", new_field: "\"Customer\".\"contact_name\"" },
                                { field: "total", new_field: "\"transaction_purchase_order\".\"total\"" },
                                { field: "payment_method", new_field: "\"Project\".project_detail->>'cash'::text" },
                                { field: "created_by", new_field: "\"Created\".\"myname\"" },
                                { field: "approved_by", new_field: "\"Approved\".\"myname\"" },
                                { field: "notes", new_field: "\"transaction_purchase_order\".\"notes\"" },
                                { field: "sales_name", new_field: "\"Project__Sales\".contact_name" },
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { query: purchaseOrderServices.readPurchaseOrderQuery(),
                                        variables : variables}
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionPurchaseOrder.transaction_purchase_order == null){
                            return [];
                        }else{
                            return response.data.GetTransactionPurchaseOrder.transaction_purchase_order;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionPurchaseOrder.transaction_purchase_order == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionPurchaseOrder.total;
                        }
                    },
                    model: {
                        fields: {
                            purchase_order_date: {type: "date"},
                            created_at: {type: "date"},
                            total: {type: "number"},
                            project_type: {type: "dropdown"},
                            term_of_payment: {type: "number"}
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            });
        },
    }
}
</script>